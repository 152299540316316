<script>
import fichaIndicadoresSchema from "./fichaIndicadoresSchema.js";
import HelexiumForm from "@/components/HelexiumForm/HelexiumForm.vue";
import ShowDataModel from "@/components/HelexiumForm/ShowDataModel.vue";
const Swal = require("sweetalert2");

export default {
  components: {
    HelexiumForm,
    ShowDataModel,
  },
  async mounted() {
    const email = JSON.parse(localStorage.setPersonaAct).correo;
    const userLogged = await this.$store.dispatch("get", { path: `Persona/GetPersonaRecuperar/${email}` });
    const data = await this.$store.dispatch("get", { path: `Sucursal/ListSucursalEmpresa/${userLogged.empresaId}` });
    const sucursales = data.map((sucursal) => {
      return {
        value: sucursal.id,
        label: sucursal.descripcion,
      };
    });

    this.sucursalSchema = [
      {
        name: "SucursalId",
        value: "",
        label: "Asignar sucursal al vehículo",
        type: "select",
        validations: "required",
        state: "error",
        options: sucursales,
      },
    ];

    this.fichaIndicadoresSchema = [...fichaIndicadoresSchema].map((element) => {
      element.state = "disabled";
      return element;
    });

    this.initData["fichaIndicadoresSchema"] = [...fichaIndicadoresSchema];
  },
  data() {
    return {
      fichaIndicadoresSchema: null,
      sucursalSchema: null,
      sucursalSeleccionada: null,
      initData: {},
      registros: [],
    };
  },
  methods: {
    guardarInformacion(data, isValidated) {
      if (isValidated) {
        let model = { ...data };
        model["FechaDeCreacion"] = this.$store.getters.getDateNow();
        model["SucursalId"] = this.sucursalSeleccionada;

        let path = "SGSSTFichaIndicadores";
        this.$store
          .getters.fetchPost({ path, data: model })
          .then((response) => response.json())
          .then((result) => {
            Swal.fire({
              title: "Información guardada",
              icon: "success",
              confirmButtonText: "Aceptar",
            })
            this.registros.push(result);
            this.watcherSucursal({ value: this.sucursalSeleccionada });
          })
          .catch((error) => {
             Swal.fire({
              title: "Error al guardar la información",
              icon: "error",
              confirmButtonText: "Aceptar",
             })
             console.log(error);
          });
      }
    },
    watcherSucursal(model) {
      if (model.value) {
        this.sucursalSeleccionada = model.value;
        this.fichaIndicadoresSchema = null;

        this.$nextTick(() => {
          this.fichaIndicadoresSchema = [...fichaIndicadoresSchema].map((element) => {
            element.state = "";
            element.value = "";
            return element;
          });
        });

        this.$store
          .getters.fetchGet({ path: `SGSSTFichaIndicadores/sucursal/${model.value}` })
          .then((response) => response.json())
          .then((result) => {
            if (result.length > 0) {
              this.registros = result;
            } else {
              this.registros = [];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    findLabelById(id) {
      return this.sucursalSchema[0].options.find((option) => option.value == id).label;
    },
  },
};
</script>

<template>
  <!-- Este archivo debe ir asocicado a empresa (llave foranea de empresas) -->
  <b-container fluid>
    <b-row class="justify-content-lg-center">
      <b-col>
        <card class="py-4 px-4">
          <HelexiumForm v-if="sucursalSchema" :schema="sucursalSchema" :observer="['SucursalId']"
            @watcher="watcherSucursal">
            <template #submit>
              <span></span>
            </template>
          </HelexiumForm>
          <template v-if="initData.fichaIndicadoresSchema">
            <ShowDataModel v-for="registro in registros" :title="registro.fechaDeCreacion"
              :data="initData.fichaIndicadoresSchema" :model="registro" :key="registro.name"></ShowDataModel>
          </template>
        </card>
        <card class="py-4 px-4">
          <HelexiumForm v-if="fichaIndicadoresSchema" title="Ficha indicadores SGSST" :schema="fichaIndicadoresSchema">
            <template #submit="{ data, isValidated }">
              <b-button :disabled="!isValidated" variant="primary"
                @click.prevent="guardarInformacion(data, isValidated)">Guardar</b-button>
            </template>
          </HelexiumForm>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
