<script>
import HelexiumInput from "@/components/HelexiumForm/HelexiumInput.vue";

export default {
  components: {
    HelexiumInput,
  },
  props: {
    schema: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    observer: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      allValidations: [],
    };
  },
  methods: {
    onSubmit() {
      console.log("Varios submit");
      console.log(this.schema);
    },
    validation(item) {
      //Aca deberia existir un emit
      if (this.observer.includes(item.name)) {
        this.$emit("watcher", item);
      }
      let index = this.allValidations.findIndex((validation) => validation.name === item.name);
      if (index == -1) {
        this.allValidations.unshift(item);
      } else {
        this.allValidations[index] = item;
      }
    },
    isValidated() {
      return !this.allValidations.reduce((total, currency) => total + currency.validation, 0);
    },
  },
  computed: {
    endData() {
      let data = {};
      if (this.schema == null) return data;
      this.schema.forEach((element) => {
        data[element.name] = element.value;
      });
      return data;
    },
  },
};
</script>

<template>
  <form @submit.prevent>
    <slot name="title">
      <h5 v-if="title" class="text-white"><strong> {{ title }} </strong></h5>
    </slot>
    <div class="grid-form" v-if="schema != null">
      
      <div v-for="element in schema" :key="element.name" :style="{ 'grid-column': 'span ' + (element.span ? element.span : 6) }">
        <HelexiumInput
          :type="element.type"
          :label="element.label"
          :name="element.name"
          :placeholder="element.placeholder"
          :help="element.help"
          :validations="element.validations"
          :options="element.options"
          :state="element.state"
          :errorMessage="element.errorMessage"
          :initialValue="element.value"
          v-model="element.value"
          @singleValidation="validation"
        />
      </div>
      
    </div>
    <slot name="submit" :data="endData" :isValidated="isValidated()">
      <button @click.prevent="onSubmit">Enviar</button>
    </slot>
  </form>
</template>

<style scoped>
.grid-form {
  display: grid;
  row-gap: 10px;
  column-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(min(80px, 100%), 1fr));
  grid-auto-flow: dense;
}
</style>
